<template>
  <div class="live_room">
    <div class="header">
      <div class="header_left">
        <span>欢迎来到直播间</span>
      </div>
      <div class="header_right">
        <el-button type="primary">设置</el-button>
      </div>
    </div>
    <div class="content">
      <div class="left"></div>
      <div class="middle">
        <div class="center" v-show="curTab == 'video'">
          <video class="center_video" ref="center_video" autoplay></video>
          <div class="center_bg" v-show="!centerVideo">
            <img :src="imgData.center_video" alt="">
          </div>
        </div>
      </div>
      <div class="right">
        <div class="small_video_type">
          <img :src="imgData.student_area" alt="" />
        </div>
        <div class="small_video_box">
          <img
            :src="imgData.video_bg"
            alt=""
            v-show="!streamsObj.student_main.streamId"
          />
          <img
            :src="imgData.toggle_video"
            alt=""
            v-show="centerVideo == 'student_main'"
          />
          <video
            src=""
            autoplay
            ref="student_main"
            class="small_video"
          ></video>
        </div>
        <div class="small_video_box">
          <img
            :src="imgData.video_bg"
            alt=""
            v-show="!streamsObj.student_assist.streamId"
          />
          <img
            :src="imgData.toggle_video"
            alt=""
            v-show="centerVideo == 'student_assist'"
          />
          <video
            src=""
            autoplay
            ref="student_assist"
            class="small_video"
          ></video>
        </div>
        <div class="small_video_type">
          <img :src="imgData.teacher_area" alt="" />
        </div>
        <div class="small_video_box">
          <img
            :src="imgData.video_bg"
            alt=""
            v-show="!streamsObj.teacher_main.streamId"
          />
          <img
            :src="imgData.toggle_video"
            alt=""
            v-show="centerVideo == 'teacher_main'"
          />
          <video
            src=""
            autoplay
            ref="teacher_main"
            class="small_video"
          ></video>
        </div>
        <div class="small_video_box">
          <img
            :src="imgData.video_bg"
            alt=""
            v-show="!streamsObj.teacher_assist.streamId"
          />
          <img
            :src="imgData.toggle_video"
            alt=""
            v-show="centerVideo == 'teacher_assist'"
          />
          <video
            src=""
            autoplay
            ref="teacher_assist"
            class="small_video"
          ></video>
        </div>
      </div>
    </div>
    <div class="dialog">
      <div class="dialog_header">
        <span>文档库</span>
      </div>
      <div class="dialog_content doc_area" id="doc_area" style="position: relative">

      </div>
      <div class="dialog_footer">
        <div class="btn" @click="uploadFileClick">上传文档</div>
        <input type="file" @change="fileUploadChange($refs.fileUpload.files[0])" ref="fileUpload" style="display: none">
      </div>
    </div>
  </div>
</template>
<script>
import video_bg from "@/assets/image/video_bg.jpg";
import toggle_video from "@/assets/image/toggle_video.jpg";
import student_area from "@/assets/image/student_area.png";
import teacher_area from "@/assets/image/teacher_area.png";
import center_video from "@/assets/image/center_video.jpg";
import config from "@/config/config.js";
//import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { getToken } from '@/api/liveRoom.js'
import { ZegoExpressDocs as ZegoDocs } from '@/assets/js/ZegoExpressDocsWeb';
import { ZegoExpressEngine } from '@/assets/js/ZegoExpressWhiteboardWeb';
export default {
  data() {
    return {
      imgData: {
        video_bg,
        toggle_video,
        student_area,
        teacher_area,
        center_video,
      },
      streamList: [],
      centerVideo: "",
      token: "",
      roomID: "",
      userId: "",
      userName: "",
      whiteboardView: "",
      zgEngine: "",
      zegoWhite: "",
      zegoDocs: "",
      docView: "",
      whiteList: [],
      ThumbnailUrlList: [],
      curTab: "video", //video  white
      uploadingList: [],
      videoList: [],
      audioList: [],
      outputList: [],
      centerVideo: "",
      userList: [],
      config: {
        pullVolume: 200,
        pushVolume: 200,
        xmt_mainVideoName: "HD Camera",
        xmt_mainVideoId: "",
        xmt_assistVideoName: "HC Camera",
        xmt_assistVideoId: "",
        xmt_audioName: "麦克风 (HC Microphone)",
        xmt_audioId: "",
        xmt_outputName: "扬声器 (HC Microphone)",
        xmt_outputId: "",
        "320_180": {
          width: 320,
          height: 180,
        },
        "420_270": {
          width: 420,
          height: 270,
        },
        "640_360": {
          width: 640,
          height: 360,
        },
        "960_540": {
          width: 960,
          height: 540,
        },
        "1280_720": {
          width: 1280,
          height: 720,
        },
        "1920_1080": {
          width: 1920,
          height: 1080,
        },
      },
      fps: 15,
      resolutionList: [
        {
          name: "320*180",
          id: "320_180",
        },
        {
          name: "420*270",
          id: "420_270",
        },
        {
          name: "640*360",
          id: "640_360",
        },
        {
          name: "960*540",
          id: "960_540",
        },
        {
          name: "1280*720",
          id: "1280_720",
        },
        {
          name: "1920*1080",
          id: "1920_1080",
        },
      ],
      videoBitrateList: [
        {
          name: "600 kbps",
          id: 600,
        },
        {
          name: "1200 kbps",
          id: 1200,
        },
        {
          name: "1500 kbps",
          id: 1500,
        },
        {
          name: "1800 kbps",
          id: 1800,
        },
        {
          name: "2500 kbps",
          id: 2500,
        },
        {
          name: "3000 kbps",
          id: 3000,
        },
        {
          name: "3500 kbps",
          id: 3500,
        },
      ],
      audioBitrateList: [
        {
          name: "36码率",
          id: 36,
        },
        {
          name: "42码率",
          id: 42,
        },
        {
          name: "48码率(默认)",
          id: 48,
        },
        {
          name: "54码率",
          id: 54,
        },
        {
          name: "60码率",
          id: 60,
        },
        {
          name: "66码率",
          id: 66,
        },
      ],
      selectVideoBitrateId: 1500,
      selectAudioBitrate: 48,
      selectResolutionId: "1280_720",
      streamsObj: {
        teacher_main: {
          streamId: '',
          stream: '',
        },
        teacher_assist: {
          streamId: '',
          stream: '',
        },
        student_main: {
          streamId: '',
          stream: '',
        },
        student_assist: {
          streamId: '',
          stream: '',
        },
      },
      mainCameraConfig: {
        audio: true,
        audioInput: '',
        audioBitrate: '',
        video: true,
        videoInput: '',
        videoQuality: 4,
        width: '',
        height: '',
        frameRate: 15,
        bitrate: 300,
        ANS: true,
        AGC: true,
        AEC: true,
      },
      assistCameraConfig: {
        audio: true,
        audioInput: '',
        audioBitrate: '',
        video: true,
        videoInput: '',
        videoQuality: 4,
        width: '',
        height: '',
        frameRate: 15,
        bitrate: 300,
        ANS: true,
        AGC: true,
        AEC: true,
      },
    };
  },
  components: {},
  computed: {},
  watch: {
    'streamsObj': {
      handler: function (){
        if(this.centerVideo && !this.streamsObj[this.centerVideo].streamId){
          this.centerVideo = ''
        }
      },
      deep: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.roomID = this.$route.params.roomID;
      this.userID = this.$route.params.userID;
      this.userName = this.$route.params.userName;
      this.fps = this.$route.params.fps;
      this.initZgEngine()
    },
    uploadFileClick(){
      if(this.uploadingList.length > 10){
        this.$message({
          message: '同时上传文件不能超过10个，请稍后再传！',
          type: 'warning'
        });
        return;
      }
      this.$refs.fileUpload.click();
      this.$refs.fileUpload.value = '';
    },
    async fileUploadChange(file){
      let docView = this.zegoDocs.createView('doc_area')
      let uploadFileRes = await this.zegoDocs.uploadFile(file, 3)
      docView.loadFile(uploadFileRes.fileID)
    },
    async initZgEngine() {
      this.zgEngine = new ZegoExpressEngine(config.appID, config.server);
      this.zgEngine.setLogConfig({
        logLevel: 'disable'
      })
      this.zgEngine.on('roomStreamUpdate', async (roomID, updateType, streamList) => {
        if(updateType == 'DELETE'){
          for (let i = 0, len = streamList.length; i < len; i++) {
            if(streamList[i].streamID.indexOf('teacher_main') == 0 && streamList[i].streamID == this.streamsObj.teacher_main.streamId){
              this.zgEngine.stopPlayingStream(streamList[i].streamID);
              this.streamsObj.teacher_main = {
                streamId: '',
                stream: ''
              }
            }else if(streamList[i].streamID.indexOf('teacher_assist') == 0 && streamList[i].streamID == this.streamsObj.teacher_assist.streamId){
              this.zgEngine.stopPlayingStream(streamList[i].streamID);
              this.streamsObj.teacher_assist = {
                streamId: '',
                stream: ''
              }
            }
          }
        }else if(updateType == 'ADD'){
          for (let i = 0, len = streamList.length; i < len; i++) {
            if(streamList[i].streamID.indexOf('teacher_main') == 0 && this.streamsObj.teacher_main.streamId){
              this.zgEngine.stopPlayingStream(this.streamsObj.teacher_main.streamId);
              let stream = await this.zgEngine.startPlayingStream(streamList[i].streamID);
              this.$refs.teacher_main.srcObject = stream
              this.streamsObj.teacher_main = {
                streamId: streamList[i].streamID,
                stream
              }
            }else if(streamList[i].streamID.indexOf('teacher_main') == 0 && !this.streamsObj.teacher_main.streamId){
              let stream = await this.zgEngine.startPlayingStream(streamList[i].streamID);
              this.$refs.teacher_main.srcObject = stream
              this.streamsObj.teacher_main = {
                streamId: streamList[i].streamID,
                stream
              }
            }else if(streamList[i].streamID.indexOf('teacher_assist') == 0 && this.streamsObj.teacher_assist.streamId){
              this.zgEngine.stopPlayingStream(this.streamsObj.teacher_assist.streamId);
              let stream = await this.zgEngine.startPlayingStream(streamList[i].streamID);
              this.$refs.teacher_assist.srcObject = stream
              this.streamsObj.teacher_assist = {
                streamId: streamList[i].streamID,
                stream
              }
            }else if(streamList[i].streamID.indexOf('teacher_assist') == 0 && !this.streamsObj.teacher_assist.streamId){
              let stream = await this.zgEngine.startPlayingStream(streamList[i].streamID);
              this.$refs.teacher_assist.srcObject = stream
              this.streamsObj.teacher_assist = {
                streamId: streamList[i].streamID,
                stream
              }
            }
          }
        }
      })
      this.token = await getToken({
        app_id: config.appID,
        id_name: this.userID
      })
      const result = await this.zgEngine.loginRoom(this.roomID, this.token, {userID: this.userID, userName: this.userName});
      if(result){
        this.whiteList = await this.zgEngine.getViewList()
        console.log(this.whiteList)
        this.whiteboardView = await this.zgEngine.createView({
          roomID: this.roomID,
          name: '白板',
          aspectWidth: 400 * 5,
          aspectHeight: 500,
          pageCount: 5,
        })
        this.whiteboardView.setToolType(8)
        this.zgEngine.attachView(this.whiteboardView, 'doc_area')
        let viewList = await this.zgEngine.getViewList()
        console.log(viewList)
        /* for(let i = 0, len = viewList.length; i < len; i++){
          console.log(viewList[i].getFileInfo())
        } */
        this.zegoDocs = new ZegoDocs({appID: config.appID, token: this.token, userID: this.userID, isTestEnv: true});
        this.zegoDocs.on('onLoadFile',async (res) => {
          console.log(res)
          let whiteboardView = await this.zgEngine.createView({
            roomID: this.roomID,
            name: '文档',
            aspectWidth: 400,
            aspectHeight: 500,
            pageCount: res.pageCount,
            fileInfo: {
              fileID: res.fileID,
              fileName: res.fileName,
              fileType: res.fileType,
              authKey: ''
            }
          })
          whiteboardView.setToolType(8)
          this.zgEngine.attachView(whiteboardView, res.viewID)
          for(let i in whiteboardView){
            console.log(i)
          }
        })
        this.docView = this.zegoDocs.createView('doc_area');
      }else{
        console.log('登录房间失败！')
      }
      this.zgEngine.on('error', (errorData) => {
        console.log(errorData)
      })
      this.zgEngine.on('viewAdd', (whiteboardView) => {
        console.log(whiteboardView)
        if(this.whiteboardView){
          this.zgEngine.destroyView(this.whiteboardView)
        }
        this.whiteboardView = whiteboardView
        this.whiteboardView.setToolType(8)
        this.zgEngine.attachView(this.whiteboardView, 'doc_area')
      })
      this.zgEngine.on('viewRemoved', (id) => {
        console.log(id)
      })
      let deviceInfo = await this.zgEngine.enumDevices()
      this.videoList = deviceInfo.cameras
      this.audioList = deviceInfo.microphones
      this.outputList = deviceInfo.speakers
      for(let i = 0, len = this.videoList.length; i < len; i++){
        if(this.videoList[i].deviceName.indexOf(this.config.xmt_mainVideoName) != -1){
          this.config.xmt_mainVideoId = this.videoList[i].deviceID
        }
        if(this.videoList[i].deviceName.indexOf(this.config.xmt_assistVideoName) != -1){
          this.config.xmt_assistVideoId = this.videoList[i].deviceID
        }
      }
      for(let i = 0, len = this.audioList.length; i < len; i++){
        if(this.audioList[i].deviceName.indexOf(this.config.xmt_audioName) != -1){
          this.config.xmt_audioId = this.audioList[i].deviceID
        }
      }
      for(let i = 0, len = this.outputList.length; i < len; i++){
        if(this.outputList[i].deviceName.indexOf(this.config.xmt_outputName) != -1){
          this.config.xmt_outputId = this.outputList[i].deviceID
        }
      }
      if(this.audioList.length > 0 && this.audioList[0].deviceID){
        this.mainCameraConfig.audioInput = this.config.xmt_audioId ? this.config.xmt_audioId : this.audioList[0].deviceID
      }
      if(this.outputList.length > 0 && this.outputList[0].deviceID){
        
      }
      if(this.videoList.length > 0 && this.videoList[0].deviceID){
        this.mainCameraConfig.videoInput = this.config.xmt_mainVideoId ? this.config.xmt_mainVideoId : this.videoList[0].deviceID
        this.mainCameraConfig.width = this.config['320_180'].width
        this.mainCameraConfig.height = this.config['320_180'].height
        this.zgEngine.createStream({
          camera: this.mainCameraConfig
        }).then(stream => {
          this.$refs.student_main.srcObject = stream
          this.zgEngine.startPublishingStream(`student_main_${this.userID}`, stream)
          this.streamsObj.student_main = {
            streamId: `student_main_${this.userID}`,
            stream,
          }
        })
        if(this.config.xmt_assistVideoId){
          this.assistCameraConfig.videoInput = this.config.xmt_assistVideoId
          this.assistCameraConfig.width = this.config['320_180'].width
          this.assistCameraConfig.height = this.config['320_180'].height
          this.zgEngine.createStream({
            camera: this.assistCameraConfig
          }).then(stream => {
            this.$refs.student_assist.srcObject = stream
            this.zgEngine.startPublishingStream(`student_assist_${this.userID}`, stream)
            this.streamsObj.student_assist = {
              streamId: `student_assist_${this.userID}`,
              stream
            }
          })
        }
      }
      console.log(deviceInfo)
      this.zgEngine.on('roomUserUpdate', (roomID, updateType, userList) => {
        this.userList = userList
      })
      this.zgEngine.on('publisherStateUpdate', result => {
        console.log('publisherStateUpdate')
        console.log(result)
      })
      this.zgEngine.on('publishQualityUpdate', (streamID, stats) => {
        //console.log('publishQualityUpdate')
        //console.log(streamID, stats)
      })
      this.zgEngine.on('roomStreamUpdate', (roomID, updateType, streamList) => {
        console.log(roomID, updateType, streamList)
      })
      this.zgEngine.on('IMRecvCustomCommand', (roomID, fromUser, command) => {
        let msg = JSON.parse(command)
        switch (msg.type){
          case 'video_layout': 
            this.moveToCenter(msg.info.centerVideo)
            break;
          case 'center_video_resolution': 
            this.selectResolutionId = msg.info.selectResolutionId
            this.dealCenterVideoResolution()
            break;
          case 'notyfy_status': 
            this.moveToCenter(msg.info.centerVideo)
            break;
        }
      })
      this.zgEngine.on("roomStateUpdate", (roomID, state, errorCode, extendedData) => {
        if (state == "DISCONNECTED") {
          console.log('DISCONNECTED')
        }
        if (state == "CONNECTING") {
          console.log('CONNECTING')
        }
        if (state == "CONNECTED") {
          console.log('CONNECTED')
        }
      });
    },
    sendMsg(msg){
      this.zgEngine.sendCustomCommand(this.roomID, JSON.stringify(msg), this.userList)
    },
    moveToCenter(type) {
      let vm = this
      if((type && !this.streamsObj[type].streamId) || this.centerVideo == type){
        return
      }
      if(type == ''){
        moveVideo(this.centerVideo, this.centerVideo)
        this.centerVideo = ''
      }else if(this.centerVideo){
        moveVideo(this.centerVideo, this.centerVideo)
        moveVideo(type, 'center_video')
        this.centerVideo = type
      }else{
        moveVideo(type, 'center_video')
        this.centerVideo = type
      }
      this.selectResolutionId = '1280_720'
      this.dealCenterVideoResolution()
      function moveVideo(streamShortName, showArea){
        vm.$refs[showArea].srcObject = vm.streamsObj[streamShortName].stream
      }
    },
    dealCenterVideoResolution(){
      switch (this.centerVideo){
        case 'student_main': 
          this.zgEngine.setVideoConfig(this.streamsObj.student_main.stream, {
            width: this.config[this.selectResolutionId].width,
            height: this.config[this.selectResolutionId].height,
            frameRate: this.fps,
            maxBitrate: this.selectVideoBitrateId,
          })
          this.zgEngine.setVideoConfig(this.streamsObj.student_assist.stream, {
            width: this.config['320_180'].width,
            height: this.config['320_180'].height,
            frameRate: 15,
            maxBitrate: 300,
          })
          break;
        case 'student_assist':
          this.zgEngine.setVideoConfig(this.streamsObj.student_main.stream, {
            width: this.config['320_180'].width,
            height: this.config['320_180'].height,
            frameRate: 15,
            maxBitrate: 300,
          })
          this.zgEngine.setVideoConfig(this.streamsObj.student_assist.stream, {
            width: this.config[this.selectResolutionId].width,
            height: this.config[this.selectResolutionId].height,
            frameRate: this.fps,
            maxBitrate: this.selectVideoBitrateId,
          })
          break;
        case '': 
          this.zgEngine.setVideoConfig(this.streamsObj.student_main.stream, {
            width: this.config['320_180'].width,
            height: this.config['320_180'].height,
            frameRate: 15,
            maxBitrate: 300,
          })
          this.zgEngine.setVideoConfig(this.streamsObj.student_assist.stream, {
            width: this.config['320_180'].width,
            height: this.config['320_180'].height,
            frameRate: 15,
            maxBitrate: 300,
          })
          break;
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.live_room {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .header {
    height: 50px;
    display: flex;
    padding: 0 20px;
    border-bottom: 1px solid #f1f1f1;
    .header_left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .header_right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .content {
    flex: 1;
    display: flex !important;
    .left {
      width: 68px;
    }
    .middle {
      flex: 1;
      overflow: hidden;
      .center{
        width: 100%;
        height: 100%;
        position: relative;
        .center_video{
          width: 100%;
          height: 100%;
        }
        .center_bg{
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 11;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .right {
      width: 200px;
      background-color: #0e1d30;
      .small_video_type {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          margin-left: 10px;
        }
      }
      .small_video_box {
        width: 200px;
        height: 113px;
        position: relative;
        .small_video {
          width: 100%;
          height: 100%;
        }
        > img {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 300;
        }
        .video_opt {
          display: none;
          position: absolute;
          height: 26px;
          width: 100%;
          left: 0;
          bottom: 0;
          z-index: 301;
          background-color: #cdcdcd;
          line-height: 26px;
          text-align: left;
          i {
            color: #666666;
            padding: 6px 10px;
            cursor: pointer;
          }
        }
      }
      .small_video_box:hover .video_opt {
        display: block;
      }
    }
  }
  .dialog{
    position: absolute;
    z-index: 1000;
    width: 400px;
    height: 600px;
    background: #3E4F64;
    left: 76px;
    top: 60px;
    display: flex;
    flex-direction: column;
    .dialog_header{
      color: #ffffff;
      height: 40px;
      width: 100%;
      background: #1C1F21;
      text-align: center;
      line-height: 40px;
    }
    .dialog_content{
      //flex: 1;
      height: 500px;
      overflow-y: auto;
    }
    .dialog_footer{
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn{
        color: #ffffff;
        background-color: #F65250;
        padding: 10px 20px;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
}
</style>